// fetchData.ts
import axios from 'axios'
import * as cheerio from 'cheerio';
import { parse as json2csvParse } from 'json2csv';
import { parse as csvParse } from 'csv-parse/sync';
import { NFLPlayer, MLBPlayer , NFLPosition, MLBPosition, Player, NFLTeams, PositionDataMLB, NBAPlayer, PositionDataNBA, NBAPosition, NBATeams} from '../data-components/Player';
import { calculatePositionRanks } from '../data-components/startupData';
import { NBATeamAbbreviations, NFLTeamAbbreviations } from '../data-components/typeGuards';

const currentYear = (new Date()).getFullYear();

interface RookieData {
  name: string;
  team: string;
  position: string;
}

interface ByeWeek {
  team: string;
  byeWeek: number;
}

interface NFLPlayerJSON {
  player_id: number;
  player_name: string;
  sportsdata_id: string;
  player_team_id: string;
  player_position_id: string;
  player_positions: string;
  player_short_name: string;
  player_eligibility: string;
  player_yahoo_positions: string;
  player_page_url: string;
  player_filename: string;
  player_square_image_url: string;
  player_image_url: string;
  player_yahoo_id: string;
  cbs_player_id: string;
  player_bye_week: string;
  player_owned_avg: number;
  player_owned_espn: number;
  player_owned_yahoo: number;
  player_ecr_delta: number | null;
  rank_ecr: number;
  rank_min: string;
  rank_max: string;
  rank_ave: string;
  rank_std: string;
  pos_rank: string;
  tier: number;
}

interface MLBPlayerJSON {
  player_id: number;
  player_name: string;
  player_short_name: string;
  player_team_id: string;
  filename: string;
  position_id: string;
  primary_position: string;
  player_positions: string;
  yahoo_player_id: string;
  player_yahoo_id: string;
  cbs_player_id: string;
  has_image: string;
  player_yahoo_positions: string;
  player_espn_positions: string;
  player_cbs_positions: string;
  player_page_url: string;
  player_square_image_url: string;
  player_image_url: string;
  rank_ecr: number;
  rank_min: string;
  rank_max: string;
  rank_ave: string;
  rank_std: string;
  pos_rank: string;
}

interface NBAPlayerJSON {
  player_id: number;
  player_name: string;
  player_short_name: string;
  player_team_id: string;
  player_position_id: string;
  player_positions: string;
  player_page_url: string;
  filename: string;
  player_square_image_url: string;
  player_image_url: string;
  player_yahoo_id: string;
  player_nbacom_id: string;
  player_cbs_id: string;
  rank_ecr: number;
  rank_min: string;
  rank_max: string;
  rank_ave: string;
  rank_std: string;
  pos_rank: string;
}

const localHost = window.location.hostname === 'localhost'
const useProxy = true;
// const proxyURL = localHost ? `http://localhost:3000/api/proxy` : `https://draftdeck-proxy.vercel.app/api/proxy`;
const proxyURL = `https://u5m1rc2bg3.execute-api.us-east-1.amazonaws.com/dev/general/proxy`;

//these are what need to be in the apiConfigs file
enum TesseractAPI {
  FantasyPros = "FantasyPros",
  Wikipedia = "Wikipedia",
  SportsDataIO = "SportsDataIO"
}

export const fetchNFLData = async (): Promise<Player[]> => {
  try {

    const URL = `https://partners.fantasypros.com/api/v1/consensus-rankings.php?sport=NFL&year=${currentYear}&week=0&id=1663&position=ALL&type=ST&scoring=PPR`;
    const response = await axiosProxy(URL, TesseractAPI.FantasyPros);
    //const csvData = response.data;
    const playerListJSON = response.data.players;
    // const playerListJSON = JSON.parse(response.data.players);

    const nflPlayers: NFLPlayer[] = playerListJSON.map((json: NFLPlayerJSON) =>
    {

        const newPlayer = new NFLPlayer
        (
          json.player_name,
          json.player_team_id,
          json.player_position_id as NFLPosition,
          json.rank_ecr,
          parseFloat(json.rank_ave),
          parsePositionRank(json.pos_rank),
          parseInt(json.player_bye_week),
          false, false, false
        );

        return newPlayer;
    });

    const calculatedPlayers = addDerivedDataNFL(nflPlayers);
    if (localHost)
    {
      console.log("fetched NFL");
    }
    return calculatedPlayers;
  }
  catch (error) {
    console.error('Error fetching NFL data:', error);
    throw error;
  }
};

const cleanCSVData = (csvData: string): string => {
  // Remove trailing commas from each line
  return csvData.split('\n').map(line => line.replace(/,\s*$/, '')).join('\n');
};

function parsePositionRank(rankString: string): number | null {
  const match = rankString.match(/\d+/);
  return match ? parseInt(match[0], 10) : null;
}


const sanitizeName= (name: string): string => {
  return name.replace(/[^a-zA-Z]/g, '');
}

const NFLPlayerMatch = (nflPlayer: NFLPlayer, rookie: RookieData) : boolean => 
{
  const nameMatch = sanitizeName(nflPlayer.name.trim().toLocaleLowerCase()) === sanitizeName(rookie.name.trim().toLocaleLowerCase());
  const positionMatch = nflPlayer.position === rookie.position;
  const teamMatch = nflPlayer.team === rookie.team;

  return nameMatch && positionMatch && teamMatch;
}

const NBAPlayerMatch = (nbaPlayer: NBAPlayer, rookie: RookieData) : boolean => 
  {
    const nameMatch = sanitizeName(nbaPlayer.name.trim().toLocaleLowerCase()) === sanitizeName(rookie.name.trim().toLocaleLowerCase());
    // const positionMatch = nbaPlayer.position === rookie.position;
    // const rookieTeamDerived = getNBATeamAbbreviations(rookie.team);
    // const teamMatch = nbaPlayer.team === rookieTeamDerived;
  
    return nameMatch && true && true;
  }

export const fetchMLBData = async (): Promise<Player[]> => {
  try {
    const URL = `https://partners.fantasypros.com/api/v1/consensus-rankings.php?sport=MLB&year=${currentYear}&week=0&id=1663&position=ALL&type=ST`;
    const response = await axiosProxy(URL, TesseractAPI.FantasyPros);

    const playerListJSONALL: MLBPlayerJSON[] = response.data.players;
    const playerListJSON500 = playerListJSONALL.slice(0,500);
    // const playerListJSON = JSON.parse(response.data.players);
    let ii = 0;
    const mlbPlayers: MLBPlayer[] = playerListJSON500.map((json: MLBPlayerJSON) =>
    {
        const mlbPositionData_DERIVED = extrapolatePositionsMLB(
          json.player_positions, 
          json.primary_position, json);

        const newPlayer = new MLBPlayer
        (
          json.player_name, 
          json.player_team_id,
          mlbPositionData_DERIVED.mainPosition,
          json.rank_ecr,
          parseFloat(json.rank_ave),
          undefined,
          false, false, false, mlbPositionData_DERIVED.otherPositions
        );
        ii++;
        return newPlayer;
    });

    // const csvData = response.data;
    // const cleanedCSVData = cleanCSVData(csvData);

    // const jsonData = csvParse(cleanedCSVData.split('\n').slice(4).join('\n'), {
    //   columns: true,
    //   skip_empty_lines: true
    // });

    // const mlbPlayers: MLBPlayer[] = jsonData.map((row: any) => {
    // const numericalFields = Object.keys(row)
    //   .filter(key => !['Player Name', 'Team', 'Position', 'Rank'].includes(key) && typeof parseInt(row[key]) === 'number');

    // const adp = numericalFields.reduce((acc, key) => acc + parseFloat(row[key]), 0) / numericalFields.length;

    // const extrapolatedMLB = extrapolatePositionsMLB(row['Position'] as string);

    // const newPlayer = new MLBPlayer(
    //     row['Player Name'],
    //     row['Team'],
    //     extrapolatedMLB.mainPosition,
    //     row['Rank'],
    //     adp, undefined, false, false, false, extrapolatedMLB.otherPositions );

    //     return newPlayer;
    // });

    const calculatedPlayers = calculatePositionRanks(mlbPlayers);

    if (localHost)
    {
      console.log("fetched MLB")
    }
    return calculatedPlayers;

  } catch (error) {
    console.error('Error fetching MLB data:', error);
    throw error;
  }
};

export const fetchNBAData = async (): Promise<Player[]> => {
  try {
    const URL = `https://partners.fantasypros.com/api/v1/consensus-rankings.php?sport=NBA&year=${currentYear}&week=0&id=1663&position=ALL&type=ST`;
    const response = await axiosProxy(URL, TesseractAPI.FantasyPros);

    const playerListJSONALL: NBAPlayerJSON[] = response.data.players;
    const playerListJSON500 = playerListJSONALL.slice(0,500);
    // const playerListJSON = JSON.parse(response.data.players);
    let ii = 0;
    const nbaPlayers: NBAPlayer[] = playerListJSON500.map((json: NBAPlayerJSON) =>
    {
        const nbaPositionData_DERIVED = extrapolatePositionsNBA(json.player_positions, json);

        const teamDerived = getNBATeamAbbreviations(json.player_team_id);

        const newPlayer = new NBAPlayer
        (
          json.player_name, 
          teamDerived,
          nbaPositionData_DERIVED.mainPosition,
          json.rank_ecr,
          parseFloat(json.rank_ave),
          undefined,
          false, false, false, nbaPositionData_DERIVED.otherPositions
        );
        ii++;
        return newPlayer;
    });

    const calculatedPlayers = calculatePositionRanks(nbaPlayers);

    const derivedPlayers = addDerivedDataNBA(calculatedPlayers as NBAPlayer[]);

    if (localHost)
    {
      console.log("fetched NBA")
    }
    return derivedPlayers;

  } catch (error) {
    console.error('Error fetching NBA data:', error);
    throw error;
  }
};

const derivedPositionMLB = (positionStringRaw: string, primaryPositionRaw: string, player: any = undefined): MLBPosition => {
  const infield = ["1B", "2B", "3B", "SS"];
  const outfield = ["LF", "RF", "CF"];
  const primaryForCSVImport = positionStringRaw.includes(",") ? positionStringRaw.split(",")[0] : positionStringRaw
  //only time we dont pass this is in csv import, so just have to take first availble
  let workingPosition = primaryPositionRaw === "" ? primaryForCSVImport : primaryPositionRaw;

  if (workingPosition.includes("P"))
  {
    workingPosition =  (positionStringRaw.includes("SP") || workingPosition === "SP") ? "SP" : "RP";
  }

  if (outfield.includes(workingPosition))
  {
    return MLBPosition.OF;
  }

  if (infield.includes(workingPosition))
  {
    switch (workingPosition)
    {
      case "1B":
        workingPosition = MLBPosition.B1;
        break;
      case "2B":  
        workingPosition =  MLBPosition.B2;
        break;
      case "3B":
        workingPosition =  MLBPosition.B3;
        break;
      case "SS":
        workingPosition = MLBPosition.SS;
        break;
    }
  }

  return workingPosition as MLBPosition;

}

const fetchNFLRookiesFromWikipedia = async (): Promise<RookieData[]> => {
  const url = `https://en.wikipedia.org/w/api.php?action=parse&page=${currentYear}_NFL_draft&format=json&origin=*`;

  try {
    const response = await axiosProxy(url, TesseractAPI.Wikipedia);
    const html = response.data.parse.text["*"];
    const $ = cheerio.load(html);
    const players: RookieData[] = [];

    $('table.wikitable.sortable.plainrowheaders tbody tr').each((index, element) => {
      const teamName = $(element).find('td').eq(1).text().trim();
      const name = $(element).find('td').eq(2).text().trim();
      const position = $(element).find('td').eq(3).text().trim();

      const teamDerived = getNFLTeamAbbreviations(teamName);

      if (name) {
        players.push({ name, team: teamDerived, position });

      }
    });

    if (localHost)
    {
      console.log("fetched rookies")
    }
    return players;
  } catch (error) {
    console.error('Error fetching NFL rookies from Wikipedia:', error);
    return [];
  }
};

const fetchNBARookiesFromWikipedia = async (): Promise<RookieData[]> => {
  const url = `https://en.wikipedia.org/w/api.php?action=parse&page=${currentYear}_NBA_draft&format=json&origin=*`;

  try {
    const response = await axiosProxy(url, TesseractAPI.Wikipedia);
    const html = response.data.parse.text["*"];
    const $ = cheerio.load(html);
    const players: RookieData[] = [];

    $('table.wikitable.sortable.plainrowheaders tbody tr').each((index, element) => {
      const teamName = $(element).find('td').eq(5).text().trim();
      const name = $(element).find('td').eq(2).text().trim().replaceAll("#", "");
      const position = $(element).find('td').eq(3).text().trim();

      const teamNameCleaned = teamName.replace(/^(.*?)\s?[\(\[].*/, '$1');


      const teamDerived = getNBATeamAbbreviations(teamNameCleaned);

      if (name) {
        players.push({ name, team: teamDerived, position });

      }
    });

    if (localHost)
    {
      console.log("fetched rookies")
    }
    return players;
  } catch (error) {
    console.error('Error fetching NBA rookies from Wikipedia:', error);
    return [];
  }
};

const getNFLTeamAbbreviations = (teamNameRaw: string): string => {

  return NFLTeams.includes(teamNameRaw) ? teamNameRaw : (NFLTeamAbbreviations[teamNameRaw] || 'Unknown Team');

}

const getNBATeamAbbreviations = (teamNameRaw: string): string => {

  return NBATeams.includes(teamNameRaw) ? teamNameRaw : (NBATeamAbbreviations[teamNameRaw] || 'Unknown Team');

}

const apiReplacementString = (apiType: string): string => {
  return `[[${apiType}]]`;
}

export const addDerivedDataNFL = async (nflPlayers: NFLPlayer[], needByeWeekData: boolean = false): Promise<Player[]> => {

  // const calculatedPlayers = calculatePositionRanks(nflPlayers);

  let byeWeeks: ByeWeek[];

  if (needByeWeekData)
  {
    byeWeeks  = await getByeWeeks();
  }

  const NFLRookies = (await fetchNFLRookiesFromWikipedia()).filter(r => Object.values(NFLPosition).includes(r.position as NFLPosition));
  
  const dataAdded: NFLPlayer[] = nflPlayers.map(p => {
    const isRookie = NFLRookies.some(r => NFLPlayerMatch(p as NFLPlayer, r));
    (p as NFLPlayer).isRookie = isRookie;

    if (needByeWeekData)
    {
      const byeWeek = byeWeeks.find(bw => bw.team === p.team)?.byeWeek;
      (p as NFLPlayer).bye = byeWeek;
    }

    
    return p as NFLPlayer;
  });

  return dataAdded;
  
}

export const addDerivedDataNBA = async (nbaPlayers: NBAPlayer[]): Promise<NBAPlayer[]> => {

  const NBARookies = (await fetchNBARookiesFromWikipedia());
  
  const dataAdded: NBAPlayer[] = nbaPlayers.map(p => {
    const isRookie = NBARookies.some(r => NBAPlayerMatch(p as NBAPlayer, r));
    (p as NBAPlayer).isRookie = isRookie;
    
    return p as NBAPlayer;
  });

  return dataAdded;
  
}

export const extrapolatePositionsMLB = (positionStringRaw: string, primaryPositionRaw: string, player: any): PositionDataMLB => 
{
  const primaryPosition = derivedPositionMLB(positionStringRaw, primaryPositionRaw, player);
  let otherPositions: MLBPosition[] = [];


  if (positionStringRaw.includes(","))
  {
    const rawOtherPositions = (positionStringRaw).split(",").filter(x => x !== primaryPositionRaw);
    const splitOtherPositions = rawOtherPositions.map(x => derivedPositionMLB("", x));
    otherPositions = Array.from(new Set(splitOtherPositions));
  }


  const data: PositionDataMLB = {mainPosition: primaryPosition, otherPositions: otherPositions};

  return data;
}

export const extrapolatePositionsNBA = (positionStringRaw: string, player: any): PositionDataNBA => 
  {
    const primaryPosition = positionStringRaw.split(",")[0] ?? positionStringRaw; //hasnt tested this
    let otherPositions: NBAPosition[] = [];
  
    if (positionStringRaw.includes(","))
    {
      // const mainPosition = positionStringRaw.split(",")[0];
      const rawOtherPositions = (positionStringRaw).split(",").filter(x => x !== primaryPosition) as NBAPosition[];
      // const splitOtherPositions = rawOtherPositions.map(x => derivedPositionMLB("", x));
      otherPositions = Array.from(new Set(rawOtherPositions));
    }
  
  
    const data: PositionDataNBA = {mainPosition: primaryPosition as NBAPosition, otherPositions: otherPositions};
  
    return data;
  }

const axiosProxy = async (apiURL: string, apiType: TesseractAPI) =>
{
  // const response = useProxy ? await axios.get(proxyURL, { params: { url: apiURL } }) : await axios.get(apiURL);
  const derivedURL = getFullProxyURL(apiURL, apiType);
  const response = useProxy ? await axios.get(derivedURL) : await axios.get(apiURL);
  return response;
}

const getFullProxyURL = (apiURL: string, apiType: TesseractAPI): string => {
  const returnURL = proxyURL + `?TesseractAPI=${apiType}&url=`+ apiURL;
  return returnURL;
}

// Function to fetch NFL bye weeks
const getByeWeeks = async (): Promise<ByeWeek[]> => {
  try {
    const url = `https://api.sportsdata.io/v3/nfl/scores/json/Schedules/${currentYear}?key=${apiReplacementString(TesseractAPI.SportsDataIO)}`;

    // Make the API request
    const { data } = await axiosProxy(url, TesseractAPI.SportsDataIO);

    // Object to store the bye weeks
    const byeWeeks: ByeWeek[] = [];

    // Process the data to extract bye weeks
    data.forEach((game: any) => {
      // Check if the game is a bye week by seeing if there's no opponent (or some similar logic based on API response)
      if (game.AwayTeam === "BYE") {
        const teamNameCleaned = getNFLTeamAbbreviations(game.HomeTeam);
        byeWeeks.push({ team: teamNameCleaned, byeWeek: game.Week}); // Use index + 1 as the week number

      }
    });

    if (localHost)
    {
      console.log("fetched byes")
    }
    return byeWeeks;
  } catch (error) {
    console.error('Error fetching NFL bye weeks:', error);
    return [];
  }
};

// getByeWeeks();