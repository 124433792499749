import React, { Component } from 'react';
import './Spinner.css'

interface SpinnerProps {
  loadingString: string;
}

class Spinner extends Component<SpinnerProps> {
  render() {
    const { loadingString } = this.props;
    return (
      <div className="spinner">
        <div className="loader"></div>
        <div className="loading-text">{loadingString}</div>
      </div>
    );
  }
}

export default Spinner;
