export const getCellStyle = (position: string) => {
    let fillColor, textColor;
  
    switch (position) {
      case 'QB':
        fillColor = 'ef74a1'; // Light Yellow
        textColor = '000000'; // Black
        break;
      case 'RB':
        fillColor = '8ff2ca'; // Pale Green
        textColor = '000000'; // Black
        break;
      case 'WR':
        fillColor = '56c9f8'; // Light Blue
        textColor = '000000'; // Black
        break;
      case 'TE':
        fillColor = 'feae58'; // Light Salmon
        textColor = '000000'; // Black
        break;
      case 'K':
        fillColor = 'b6b9ff'; // Plum
        textColor = '000000'; // Black
        break;
      case 'DST':
        fillColor = 'd3d3d3'; // Light Grey
        textColor = '000000'; // Black
        break;
      default:
        fillColor = 'FFFFFF'; // White
        textColor = '000000'; // Black
        break;
    }
  
    return {
      fill: {
        fgColor: { rgb: fillColor }
      },
      font: {
        color: { rgb: textColor }
      }
    };
  };

export const formatDateMMDDYYYY = (date: Date) => {
const month = (date.getMonth() + 1).toString().padStart(2, '0');
const day = date.getDate().toString().padStart(2, '0');
const year = date.getFullYear().toString();

return `${month}-${day}-${year}`;
}
