import { Player, NFLPlayer, NFLPosition, MLBPlayer, MLBPosition, NBAPlayer, NBAPosition } from './Player';

export const isNFLPlayer = (player: Player): player is NFLPlayer => {
  return player instanceof NFLPlayer && Object.values(NFLPosition).includes(player.position as NFLPosition);
};

export const isMLBPlayer = (player: Player): player is MLBPlayer => {
  return player instanceof MLBPlayer && Object.values(MLBPosition).includes(player.position as MLBPosition);
};

export const isNBAPlayer = (player: Player): player is NBAPlayer => {
  return player instanceof NBAPlayer && Object.values(NBAPosition).includes(player.position as NBAPosition);
};

export const NFLTeamAbbreviations: { [key: string]: string } = {
  'Arizona Cardinals': 'ARI',
  'Atlanta Falcons': 'ATL',
  'Baltimore Ravens': 'BAL',
  'Buffalo Bills': 'BUF',
  'Carolina Panthers': 'CAR',
  'Chicago Bears': 'CHI',
  'Cincinnati Bengals': 'CIN',
  'Cleveland Browns': 'CLE',
  'Dallas Cowboys': 'DAL',
  'Denver Broncos': 'DEN',
  'Detroit Lions': 'DET',
  'Green Bay Packers': 'GB',
  'Houston Texans': 'HOU',
  'Indianapolis Colts': 'IND',
  'Jacksonville Jaguars': 'JAC',
  'Kansas City Chiefs': 'KC',
  'Las Vegas Raiders': 'LV',
  'Los Angeles Chargers': 'LAC',
  'Los Angeles Rams': 'LAR',
  'Miami Dolphins': 'MIA',
  'Minnesota Vikings': 'MIN',
  'New England Patriots': 'NE',
  'New Orleans Saints': 'NO',
  'New York Giants': 'NYG',
  'New York Jets': 'NYJ',
  'Philadelphia Eagles': 'PHI',
  'Pittsburgh Steelers': 'PIT',
  'San Francisco 49ers': 'SF',
  'Seattle Seahawks': 'SEA',
  'Tampa Bay Buccaneers': 'TB',
  'Tennessee Titans': 'TEN',
  'Washington Commanders': 'WAS',
  'WSH': 'WAS',
  'JAX': 'JAC'
};

export const NBATeamAbbreviations: { [key: string]: string } = {
  'Atlanta Hawks': 'ATL',
  'Boston Celtics': 'BOS',
  'Brooklyn Nets': 'BKN',
  'Charlotte Hornets': 'CHA',
  'Chicago Bulls': 'CHI',
  'Cleveland Cavaliers': 'CLE',
  'Detroit Pistons': 'DET',
  'Indiana Pacers': 'IND',
  'Miami Heat': 'MIA',
  'Milwaukee Bucks': 'MIL',
  'New York Knicks': 'NYK',
  'Orlando Magic': 'ORL',
  'Philadelphia 76ers': 'PHI',
  'Toronto Raptors': 'TOR',
  'Washington Wizards': 'WAS',
  'Dallas Mavericks': 'DAL',
  'Denver Nuggets': 'DEN',
  'Golden State Warriors': 'GSW',
  'Houston Rockets': 'HOU',
  'Los Angeles Clippers': 'LAC',
  'Los Angeles Lakers': 'LAL',
  'Memphis Grizzlies': 'MEM',
  'Minnesota Timberwolves': 'MIN',
  'New Orleans Pelicans': 'NOP',
  'Oklahoma City Thunder': 'OKC',
  'Phoenix Suns': 'PHX',
  'Portland Trail Blazers': 'POR',
  'Sacramento Kings': 'SAC',
  'San Antonio Spurs': 'SAS',
  'Utah Jazz': 'UTA',
  'PHO': 'PHX',
  'UTH': 'UTA',
  'NOR': 'NOP'
};