import React, { useState } from 'react';
import Modal from 'react-modal';
import './Header.css';

const Header: React.FC = () => {


  return (
    <header className="App-header">
      <div className="title-container">
        <h1>Draft Deck</h1>
        {/* <h2>Drag and drop rankings tool for Fantasy Drafts</h2> */}
      </div>
      {/* <div className="button-container">
        <button onClick={openModal} className="info-button">Info</button>
      </div> */}

    </header>
  );
};

export default Header;
