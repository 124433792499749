import React from 'react';
import styles from './Tooltip.module.css';

interface TooltipProps {
  text: string;
  tooltipText: string;
  direction: 'right' | 'left';
}

const Tooltip: React.FC<TooltipProps> = ({ text, tooltipText, direction }) => (
  <div className={styles.tooltipContainer}>
    {text}
    <span className={`${styles.tooltipText} ${direction === "right" ? styles.RIGHTDOWN : styles.LEFTDOWN}`}>
      {tooltipText}
    </span>
  </div>
);

export default Tooltip;
