import { parse as csvParse } from 'csv-parse/sync';
import { Player, NFLPlayer, NFLPosition, MLBPlayer, MLBPosition , PositionDataMLB, NBAPlayer} from '../data-components/Player';
import { getCellStyle, formatDateMMDDYYYY } from '../utils/formattingUtils';
import { parse as json2csvParse } from 'json2csv';
import { isMLBPlayer } from '../data-components/typeGuards';
import { extrapolatePositionsMLB, extrapolatePositionsNBA } from '../apiHelpers/apiData';


export const importNFLFromCSV = (csvData: string): NFLPlayer[] => {

  try{
    const records = csvParse(csvData, {
      columns: true,
      skip_empty_lines: true
    });
  
    
    const nflPlayers: NFLPlayer[] = records.map((row: any) => {
      const { name, team, position, overallRank, adp, projectedTotalPoints, positionRank, bye, isRookie, favorite, drafted, ...additionalProps } = row;
  
      const rookieLowercase = isRookie ? (isRookie as string).toLocaleLowerCase() : "false";
      const favoriteLowercase = favorite ? (favorite as string).toLocaleLowerCase() : "false";
      const draftedLowerCase = drafted ? (drafted as string).toLocaleLowerCase() : "false";
  
      const newPlayer = new NFLPlayer(
        name,
        team,
        position as NFLPosition,
        parseInt(overallRank),
        adp ? parseFloat(adp) : null,
        //projectedTotalPoints ? parseFloat(projectedTotalPoints) : null,
        positionRank ? parseInt(positionRank) : null,
        bye ? parseInt(bye) : null,
        rookieLowercase === 'true',
        favoriteLowercase === 'true', draftedLowerCase === 'true', additionalProps
      );
      return newPlayer;
    });
  
    return nflPlayers;
  }
  catch (e) {
    throw e;
  }

};

// Function to handle CSV export
export const exportNFLToCSV = (players: Player[], playerFilter: string, favoriteFilter: boolean, rookieFilter: boolean) => {

  const additionalFields = players[0].additionalProps ? Object.keys(players[0].additionalProps) : [];

  const staticFields = [
    { label: 'name', value: 'name' },
    { label: 'team', value: 'team' },
    { label: 'position', value: 'position' },
    { label: 'overallRank', value: 'overallRank' },
    { label: 'adp', value: 'adp' },
    { label: 'positionRank', value: 'positionRank' },
    { label: 'bye', value: 'bye' },
    { label: 'isRookie', value: 'isRookie' },
    { label: 'favorite', value: 'favorite' },
    { label: 'drafted', value: 'drafted' },
    { label: 'PositionRankText', value: 'PositionRankText' },
  ];

  // Map additionalProps keys to the field structure
  const dynamicFields = additionalFields.map((field) => ({
    label: field,
    value: field,
  }));

  const fields = [...staticFields, ...dynamicFields];

  const favoriteText = "FAVORITES";
  const rookieText = "ROOKIES";
  let fileNameEnhancement = playerFilter;
  if (favoriteFilter) {
    fileNameEnhancement += `-${favoriteText}`;
  }

  if (rookieFilter) {
    fileNameEnhancement += `-${rookieText}`;
  }


  const enhancedPlayers = players.map(enhanceNFLPlayerData);
  const csv = json2csvParse(enhancedPlayers, { fields: fields });
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `NFLPlayers_${fileNameEnhancement}_${formatDateMMDDYYYY(new Date())}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const enhanceNFLPlayerData = (player: any) => {
  const enhanced = { ...player };
  enhanced.PositionRankText = (player as NFLPlayer).getPositionRankString();
  return enhanced;
};

const enhanceMLBPlayerData = (player: any) => {
  const enhanced = { ...player };
  enhanced.PositionRankText = (player as MLBPlayer).getPositionRankString();
  const rawPositionArray = ([enhanced.position, ...enhanced.otherPositions] as string[]);
  const prettyPositionArray = rawPositionArray.map((po: string) => {
    return MLBPlayer.getPrettyPosition(po);
  })
  enhanced.position = prettyPositionArray.join(",");
  return enhanced;
};

const enhanceNBAPlayerData = (player: any) => {
  const enhanced = { ...player };
  enhanced.PositionRankText = (player as NBAPlayer).getPositionRankString();
  const rawPositionArray = ([enhanced.position, ...enhanced.otherPositions] as string[]);
  const prettyPositionArray = rawPositionArray.map((po: string) => {
    return NBAPlayer.getPrettyPosition(po);
  })
  enhanced.position = prettyPositionArray.join(",");
  return enhanced;
};

export const importMLBFromCSV = (csvData: string): MLBPlayer[] => {
  const records = csvParse(csvData, {
    columns: true,
    skip_empty_lines: true
  });


  const mlbPlayers: MLBPlayer[] = records.map((row: any) => {
    const { name, team, position, overallRank, adp, positionRank, isRookie, favorite, drafted, ...additionalProps } = row;
    const extrapolatedPositions =  extrapolatePositionsMLB(position, "", undefined);
    const newPlayer = new MLBPlayer(
      name,
      team,
      extrapolatedPositions.mainPosition,
      parseInt(overallRank),
      adp ? parseFloat(adp) : null,
      positionRank ? parseInt(positionRank) : null,
      isRookie === 'true',
      favorite === 'true', false, extrapolatedPositions.otherPositions, additionalProps 
    );
    return newPlayer;
  });

  return mlbPlayers;
};

export const importNBAFromCSV = (csvData: string): NBAPlayer[] => {
  const records = csvParse(csvData, {
    columns: true,
    skip_empty_lines: true
  });


  const nbaPlayers: NBAPlayer[] = records.map((row: any) => {
    const { name, team, position, overallRank, adp, positionRank, isRookie, favorite, drafted, ...additionalProps } = row;
    const extrapolatedPositions =  extrapolatePositionsNBA(position, "");
    const newPlayer = new NBAPlayer(
      name,
      team,
      extrapolatedPositions.mainPosition,
      parseInt(overallRank),
      adp ? parseFloat(adp) : null,
      positionRank ? parseInt(positionRank) : null,
      isRookie === 'true',
      favorite === 'true', false, extrapolatedPositions.otherPositions, additionalProps 
    );
    return newPlayer;
  });

  return nbaPlayers;
};

export const exportMLBToCSV = (players: Player[], playerFilter: string, favoriteFilter: boolean, rookieFilter: boolean) => {
  const additionalFields = players[0].additionalProps ? Object.keys(players[0].additionalProps) : [];

  const staticFields = [
    { label: 'name', value: 'name' },
    { label: 'team', value: 'team' },
    { label: 'position', value: 'position' },
    { label: 'overallRank', value: 'overallRank' },
    { label: 'adp', value: 'adp' },
    { label: 'positionRank', value: 'positionRank' },
    { label: 'isRookie', value: 'isRookie' },
    { label: 'favorite', value: 'favorite' },
    { label: 'drafted', value: 'drafted' },
    { label: 'PositionRankText', value: 'PositionRankText' },
  ];

  // Map additionalProps keys to the field structure
  const dynamicFields = additionalFields.map((field) => ({
    label: field,
    value: field,
  }));

  const fields = [...staticFields, ...dynamicFields];
  const favoriteText = "FAVORITES";
  const rookieText = "ROOKIES";
  let fileNameEnhancement = playerFilter;
  if (favoriteFilter) {
    fileNameEnhancement += `-${favoriteText}`;
  }

  if (rookieFilter) {
    fileNameEnhancement += `-${rookieText}`;
  }


  const enhancedPlayers = players.map(enhanceMLBPlayerData);
  const csv = json2csvParse(enhancedPlayers, { fields });
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `MLBPlayers_${fileNameEnhancement}_${formatDateMMDDYYYY(new Date())}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const exportNBAToCSV = (players: Player[], playerFilter: string, favoriteFilter: boolean, rookieFilter: boolean) => {
  const additionalFields = players[0].additionalProps ? Object.keys(players[0].additionalProps) : [];

  const staticFields = [
    { label: 'name', value: 'name' },
    { label: 'team', value: 'team' },
    { label: 'position', value: 'position' },
    { label: 'overallRank', value: 'overallRank' },
    { label: 'adp', value: 'adp' },
    { label: 'positionRank', value: 'positionRank' },
    { label: 'isRookie', value: 'isRookie' },
    { label: 'favorite', value: 'favorite' },
    { label: 'drafted', value: 'drafted' },
    { label: 'PositionRankText', value: 'PositionRankText' },
  ];

  // Map additionalProps keys to the field structure
  const dynamicFields = additionalFields.map((field) => ({
    label: field,
    value: field,
  }));

  const fields = [...staticFields, ...dynamicFields];
  const favoriteText = "FAVORITES";
  const rookieText = "ROOKIES";
  let fileNameEnhancement = playerFilter;
  if (favoriteFilter) {
    fileNameEnhancement += `-${favoriteText}`;
  }

  if (rookieFilter) {
    fileNameEnhancement += `-${rookieText}`;
  }


  const enhancedPlayers = players.map(enhanceNBAPlayerData);
  const csv = json2csvParse(enhancedPlayers, { fields });
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `NBAPlayers_${fileNameEnhancement}_${formatDateMMDDYYYY(new Date())}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// const enhanceMLBPlayerData = (player: any) => {
//   const enhanced = { ...player };
//   enhanced.PositionRankText = (player as NFLPlayer).getPositionRankString();
//   return enhanced;
// };