import React, { FC, useState } from 'react';
import './MessageBox.css';  // Ensure this path is correct

export enum MessageType {
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
  Error = 'error'
}

export interface MessageInfo {
  text: string;
  type: MessageType;
}

export interface MessageBoxProps {
  message: string;
  type?: MessageType;
  onClose?: () => void;
}

interface MessageBoxType extends FC<MessageBoxProps> {
  createMessageInfo(messageString: string, messageType: MessageType): MessageInfo;
  createMessageInfo(error: Error): MessageInfo;
}

const MessageBox: MessageBoxType = ({ message, type = 'info', onClose }) => {
  const [visible, setVisible] = useState(true);

  const handleClose = () => {
    setVisible(false);
    if (onClose) {
      onClose();
    }
  };

  if (!visible) return null;

  return (
    <div className={`message-box ${type}`}>
      <div className="message-content-wrapper">
        <p className="message-content">{message}</p>
        <button className="close-button" onClick={handleClose}>&times;</button>
      </div>
    </div>
  );
};

// Implement the overloaded method
MessageBox.createMessageInfo = (arg1: string | Error, messageType?: MessageType): MessageInfo => {
  if (typeof arg1 === 'string' && messageType) {
    // Handle the string and MessageType case
    return { text: arg1, type: messageType };
  } else if (arg1 instanceof Error) {
    // Handle the Error case
    return { text: arg1.message, type: MessageType.Error };
  }
  
  throw new Error('Invalid arguments provided to createMessageInfo');
};


export default MessageBox;
