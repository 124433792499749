import React from 'react';
import './Attributions.css'

function Attributions() {
  return (
    <div className='iconAttribution'>
      <a href="https://www.flaticon.com/free-icons/star" title="Star icons">
        Star icons created by Pixel perfect - Flaticon
      </a><br />
      <a href="https://www.flaticon.com/free-icons/delete" title="Delete icons">
        Minus icons created by Roundicons - Flaticon
      </a><br />
      <a href="https://www.flaticon.com/free-icons/close" title="Close icons">
        Close icons created by Miftakhul Rizky - Flaticon
      </a><br />
      <a href="https://www.sportslogos.net/" title="Team Logos">
        Team Logos - SportsLogos.net
      </a><br />
      <a href="https://www.SportsData.io/" title="SportsData">
        API Data - SportsDataIO
      </a><br />
      <a href="https://www.fantasypros.com/" title="FantasyPros">
        API Data - FantasyPros
      </a><br />
      <a href="https://www.wikipedia.org/" title="Wikipedia">
        Wikipedia
      </a><br /><br />
      <div className="notLink">NOT AFFILIATED WITH NFL OR MLB</div><br />
    </div>
  );
}

export default Attributions;
