import React, { useState, useEffect, useRef } from 'react';
import { Player, NFLPlayer, MLBPlayer, NBAPlayer } from '../src/data-components/Player';
import { initializeDefaultData, DefaultAPIData } from './data-components/startupData';
import { addDerivedDataNFL } from './apiHelpers/apiData';
import PlayerList from '../src/components/PlayerList';
import Header from './components/Header';
import Footer from './components/Footer';
import NFLLogo from './icons/NFL.png'
import MLBLogo from './icons/MLB.png'
import NBALogo from './icons/NBA.png'
import Spinner from './components/Spinner';
import './App.css'
import { forceDelay } from './utils/miscUtils';
import MessageBox, {MessageInfo, MessageType} from './components/MessageBox';



export enum Sport {
  NFL = 'NFL',
  MLB = 'MLB',
  NBA = 'NBA',
  MISC = 'MISC'
}

// preloadImages();

const App: React.FC = () => {
  const [players, setPlayers] = useState<Player[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedSport, setSelectedSport] = useState<Sport>(Sport.NFL);
  const [playerListLoading, setPlayerListLoading] = useState(true);
  const [playersImporting, setPlayersImporting] = useState(false);
  const [defaultAPIData, setDefaultAPIData] = useState<DefaultAPIData>();
  const [validDataInCache, setValidDataInCache] = useState(false);
  const [finishedReadingCache, setFinishedReadingCache] = useState(false);
  const hasInitialized = useRef(false);
  const [message, setMessage] = useState<MessageInfo | null>(null);

  const currentTabCachedVariable = `DraftDeck-CurrentTab`;
 

    const convertToNFLPlayers = (players: any[]): NFLPlayer[] => {
      return players.map(NFLPlayer.fromJSON);
    };

    const convertToMLBPlayers = (players: any[]): MLBPlayer[] => {
      return players.map(MLBPlayer.fromJSON);
    };

    const convertToNBAPlayers = (players: any[]): NBAPlayer[] => {
      return players.map(NBAPlayer.fromJSON);
    };

    const saveCurrentTab= () => {
      localStorage.setItem(currentTabCachedVariable, selectedSport);
    }

    const loadCurrentTab= () => {
        const tabKey = currentTabCachedVariable;
        const tabString = localStorage.getItem(tabKey) || Sport.NFL;
        const savedTab = tabString as Sport;

        if (savedTab && Object.values(Sport).includes(savedTab))
        {
          setSelectedSport(savedTab)
        }
    }


    const loadPlayers = () => {
      setPlayerListLoading(true);
        const storageKey = `${selectedSport}PlayersStorage`;
        const savedPlayers = localStorage.getItem(storageKey);
  
        if (savedPlayers && savedPlayers.length>2) {
          const parsedPlayers = JSON.parse(savedPlayers);
          const loadedPlayers = convertToPlayers(selectedSport, parsedPlayers);
          setPlayers(loadedPlayers);
          setPlayerListLoading(false);
        } else {
          const defaults = defaultAPIData ? getDefaultPlayers() : [];
          setPlayers(defaults);
        }

        setIsLoaded(true);
        // ;

    }
  
    const savePlayers = (sport: Sport, players: any[]) => {
      const storageKey = `${sport}PlayersStorage`;
      localStorage.setItem(storageKey, JSON.stringify(players));

      // const mlbStorageKey = "MLBPlayersStorage";
      // const savedMLB = localStorage.getItem(mlbStorageKey);

      // if (sport === Sport.NFL && (!savedMLB || savedMLB.length < 3))
      // {
      //   localStorage.setItem(storageKey, JSON.stringify(defaultAPIData?.mlbPlayers));
      // }
    };
  
    const convertToPlayers = (sport: Sport, players: any[]) => {
      switch (sport) {
        case Sport.NFL:
          return convertToNFLPlayers(players);
        case Sport.MLB:
          return convertToMLBPlayers(players);
        case Sport.NBA:
          return convertToNBAPlayers(players);
        case Sport.MISC:
          return convertToNFLPlayers(players);
        default:
          return convertToNFLPlayers(players);
      }
    };
  
    const onDragEnd = async (newPlayers: Player[], needToCallAPIs: boolean, importing: boolean = false) => {
      
      setPlayerListLoading(true);
      setPlayersImporting(importing);

      if(needToCallAPIs)
      {
        try {
          switch(selectedSport)
          {
              case Sport.NFL:
                const updatedPlayers = await addDerivedDataNFL(newPlayers as NFLPlayer[], true);
                setPlayers(updatedPlayers);
                break;
              case Sport.MLB:
                setPlayers([...newPlayers]);
                break;
              case Sport.NBA:
                setPlayers([...newPlayers]);
                break;
          }
          
        } catch (error) {
          console.error('Error during drag end processing:', error);
          setMessage(MessageBox.createMessageInfo(error as Error))
        } finally {
        }
      }
      else
      {
        setPlayers([...newPlayers]);
        // await forceDelay(3000);
  
      }

      setPlayerListLoading(false);
      setPlayersImporting(false);
  
  
    };
  
    const onReset = async (draftOccured: boolean) => {
      
      setPlayerListLoading(true);

      //need to check default players, if it's empty, run API calls, else just set default

      if (!defaultAPIData)
      {
        await makeAPICalls();
      }

      else
      {
        setPlayers(getDefaultPlayers()); //this
      }
      
      setPlayerListLoading(false);

    };

    const OnMessageUpdate = (message: MessageInfo | null) => {
      setMessage(message);
    }
  
    const renderPlayerList = () => {
      // if (initialStartupLoading || playerListLoading)
        if (playerListLoading)
      {
        const loadType = playersImporting ? "Importing" : "Loading";

        return <Spinner loadingString={`${loadType} ${selectedSport} Data...`} />;
      }
      return <PlayerList players={players} onDragEnd={onDragEnd} onReset={onReset} sport={selectedSport} onMessageUpdate={OnMessageUpdate} />;
    };
  
    const getDefaultPlayers = () => {
      if (!defaultAPIData)
      {
        console.error("default API Data empty")
        return [];
      }
      switch (selectedSport) {
        case Sport.NFL:
          return NFLPlayer.deepCopy(defaultAPIData.nflPlayers);;
        case Sport.MLB:
          return MLBPlayer.deepCopy(defaultAPIData.mlbPlayers);
        case Sport.NBA:
          return NBAPlayer.deepCopy(defaultAPIData.nbaPlayers)
        case Sport.MISC:
          return [];
        default:
          return [];
      }
    };

    const makeAPICalls = async () => {
      try {
        const defaults = await initializeDefaultData();
        setDefaultAPIData(defaults);
        // setInitialStartupLoading(false);
        
      } catch (error) {
        setMessage(MessageBox.createMessageInfo(error as Error));
        // setInitialStartupLoading(false);
      }

    };

    const checkAllStates = () => {
      console.log(
        `playersCOUNT: ${players.length} 
        isLoaded: ${isLoaded} 
        
        selectedSport: ${selectedSport} 
        playerListLoading: ${playerListLoading} 
        playersImporting: ${playersImporting} 
        defaultData: ${defaultAPIData?.mlbPlayers.length || 0 + 0 || defaultAPIData?.nflPlayers.length}
        hasInitialized: ${hasInitialized} 
        
        `);
    }

    const startupDataValid = () : {validNFL: boolean, validMLB: boolean, validNBA: boolean} => {
      const nflKey = `${Sport.NFL}PlayersStorage`;
      const savedNFLPlayers = localStorage.getItem(nflKey);
      const parsedNFL = savedNFLPlayers ? JSON.parse(savedNFLPlayers) : "";
      const validNFL = savedNFLPlayers !== null && savedNFLPlayers !== undefined && Array.isArray(parsedNFL) && parsedNFL.length > 0;

      const mlbKey = `${Sport.MLB}PlayersStorage`;
      const savedMLBPlayers = localStorage.getItem(mlbKey);
      const parsedMLB = savedMLBPlayers ? JSON.parse(savedMLBPlayers) : "";
      const validMLB = savedMLBPlayers !== null && savedMLBPlayers !== undefined && Array.isArray(parsedMLB) && parsedMLB.length > 0;

      const nbaKey = `${Sport.NBA}PlayersStorage`;
      const savedNBAPlayers = localStorage.getItem(nbaKey);
      const parsedNBA = savedNBAPlayers ? JSON.parse(savedNBAPlayers) : "";
      const validNBA = savedNBAPlayers !== null && savedNBAPlayers !== undefined && Array.isArray(parsedNBA) && parsedNBA.length > 0;

      const validData: {validNFL: boolean, validMLB: boolean, validNBA: boolean} = {validNFL, validMLB, validNBA};

      return validData;
    }

    //call apis on startup if blank (new browser)
    useEffect(() => {
      const initialize = async () => {
        
        await makeAPICalls();  // Await the async function
    
        setPlayerListLoading(false);
      };

      if (!validDataInCache && finishedReadingCache)
      {
        initialize();  // Call the async function
      }    

      
      
    }, [validDataInCache, finishedReadingCache]); 

    //real startup
    useEffect(() => {
      //load saved tab
      loadCurrentTab();

      const validCheck = startupDataValid();

      setValidDataInCache(validCheck.validNFL && validCheck.validMLB && validCheck.validNBA);
      setFinishedReadingCache(true);
      
    }, []);
  
  //load players when it ready
  useEffect(() => {
    if (!isLoaded && finishedReadingCache) {
      loadPlayers();
    }
  }, [selectedSport, isLoaded, finishedReadingCache, validDataInCache]);

  //set data from API call returns
  useEffect(() => {
    if (defaultAPIData)
    {
      const defaults = getDefaultPlayers();
      setPlayers(defaults);

      const validCheck = startupDataValid();

      if (!validCheck.validNFL)
      {
        const storageKey = `${Sport.NFL}PlayersStorage`;
        localStorage.setItem(storageKey, JSON.stringify(defaultAPIData.nflPlayers));
      }

      if (!validCheck.validMLB)
      {
        const storageKey = `${Sport.MLB}PlayersStorage`;
        localStorage.setItem(storageKey, JSON.stringify(defaultAPIData.mlbPlayers));
      }

      if (!validCheck.validNBA)
        {
          const storageKey = `${Sport.NBA}PlayersStorage`;
          localStorage.setItem(storageKey, JSON.stringify(defaultAPIData.nbaPlayers));
        }
  


    }
  }, [defaultAPIData]);

  //save players when chnged
  useEffect(() => {
    if (isLoaded) {
      savePlayers(selectedSport, players);
    }
  }, [players, isLoaded, selectedSport, playerListLoading]);

  //save tab selected
  useEffect(() => {
    saveCurrentTab();
  }, [selectedSport]);
  
  return (
    <div className='App'>
      <Header />
      <div className="tabs">
        <button 
          onClick={() => { setPlayers([]); setIsLoaded(false); setSelectedSport(Sport.NFL); }} 
          className={`tabButtons ${selectedSport === Sport.NFL ? 'active' : ''}`}
        >
          <img src={NFLLogo} alt="NFL" className="tabIcon nfl" />NFL 
        </button>
        <button 
          onClick={() => { setPlayers([]); setIsLoaded(false); setSelectedSport(Sport.MLB); }} 
          className={`tabButtons ${selectedSport === Sport.MLB ? 'active' : ''}`}
        >
          <img src={MLBLogo} alt="MLB" className="tabIcon mlb" />MLB 
        </button>
        <button 
          onClick={() => { setPlayers([]); setIsLoaded(false); setSelectedSport(Sport.NBA); }} 
          className={`tabButtons ${selectedSport === Sport.NBA ? 'active' : ''}`}
        >
          <img src={NBALogo} alt="NBA" className="tabIcon mlb" />NBA 
        </button>
        {/* <button 
          onClick={() => { setPlayers([]); setIsLoaded(false); setSelectedSport(Sport.MISC); }} 
          className={`tabButtons ${selectedSport === Sport.MISC ? 'active' : ''}`}
        >
          MISC
        </button> */}
      </div>
      {message && (
          <MessageBox message={message.text} type={message.type || 'info'} onClose={() =>{setMessage(null)}} />
        )}
        {/* <div>{defaultAPIData ? "defaultAPIData has data" : "no data"}</div>
        <div>{defaultAPIData?.nflPlayers ? "defaultAPIData?.nflPlayers has data" : "no nfl"}</div>
        <div>{defaultAPIData?.mlbPlayers ? "defaultAPIData?.mlbPlayers has data" : "no mlb"}</div>
        <div>{playerListLoading ? "playerListLoading true" : "playerListLoading false"}</div>
        <div>{validDataInCache ? "validDataInCache true" : "validDataInCache false"}</div>
        <div>{finishedReadingCache ? "finishedReadingCache true" : "finishedReadingCache false"}</div> */}
      <div className={`App ${playerListLoading ? 'loading' : ''}`}>
        {renderPlayerList()}
      </div>
      <Footer />
    </div>
  );
};

export default App;
